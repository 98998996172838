exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-caroudel-page-js": () => import("./../../../src/pages/caroudel-page.js" /* webpackChunkName: "component---src-pages-caroudel-page-js" */),
  "component---src-pages-college-girls-js": () => import("./../../../src/pages/collegeGirls.js" /* webpackChunkName: "component---src-pages-college-girls-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-donate-us-js": () => import("./../../../src/pages/donateUs.js" /* webpackChunkName: "component---src-pages-donate-us-js" */),
  "component---src-pages-fatwa-js": () => import("./../../../src/pages/fatwa.js" /* webpackChunkName: "component---src-pages-fatwa-js" */),
  "component---src-pages-gallary-js": () => import("./../../../src/pages/gallary.js" /* webpackChunkName: "component---src-pages-gallary-js" */),
  "component---src-pages-girls-inter-college-js": () => import("./../../../src/pages/girlsInterCollege.js" /* webpackChunkName: "component---src-pages-girls-inter-college-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/Introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-jamia-chishtiya-footer-js": () => import("./../../../src/pages/jamiaChishtiyaFooter.js" /* webpackChunkName: "component---src-pages-jamia-chishtiya-footer-js" */),
  "component---src-pages-jamia-chishtiya-js": () => import("./../../../src/pages/jamiaChishtiya.js" /* webpackChunkName: "component---src-pages-jamia-chishtiya-js" */),
  "component---src-pages-lecture-series-js": () => import("./../../../src/pages/lectureSeries.js" /* webpackChunkName: "component---src-pages-lecture-series-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-mujallah-js": () => import("./../../../src/pages/mujallah.js" /* webpackChunkName: "component---src-pages-mujallah-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sabri-lecture-js": () => import("./../../../src/pages/sabriLecture.js" /* webpackChunkName: "component---src-pages-sabri-lecture-js" */),
  "component---src-pages-sajjada-nasheens-hadhrat-shah-js": () => import("./../../../src/pages/sajjadaNasheens/hadhratShah.js" /* webpackChunkName: "component---src-pages-sajjada-nasheens-hadhrat-shah-js" */),
  "component---src-pages-sajjada-nasheens-js": () => import("./../../../src/pages/sajjadaNasheens.js" /* webpackChunkName: "component---src-pages-sajjada-nasheens-js" */),
  "component---src-pages-sajjada-nasheens-nayyare-millat-js": () => import("./../../../src/pages/sajjadaNasheens/nayyareMillat.js" /* webpackChunkName: "component---src-pages-sajjada-nasheens-nayyare-millat-js" */),
  "component---src-pages-sylla-bus-js": () => import("./../../../src/pages/sylla-bus.js" /* webpackChunkName: "component---src-pages-sylla-bus-js" */),
  "component---src-pages-urs-js": () => import("./../../../src/pages/urs.js" /* webpackChunkName: "component---src-pages-urs-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

